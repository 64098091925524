
import { getPropertyOwnersQuery } from "~/helpers/apollo/apollo-property-query-helpers.js";
import PropertyOwners from "../components/mainproperty/PropertyOwners.vue";
import CondoOwners from "../components/condo/CondoOwners.vue";
import BuildingOnForeignPlotOwners from "../components/buildingonforeignplot/BuildingOnForeignPlotOwners.vue";

export default {
  title() {
    return `${this.$t("MENU_OWNERS")}`;
  },
  apollo: {
    property: getPropertyOwnersQuery,
  },
  components: {
    PropertyOwners,
    CondoOwners,
    BuildingOnForeignPlotOwners,
  },
  computed: {
    loading: function () {
      return this.property == null || this.$apollo.queries.property.loading;
    },
    isProperty: function () {
      return this.property.plots != null;
    },
    isCondo: function () {
      return this.property.condo != null;
    },
    isBuildingOnForeignPlot: function () {
      return this.property.buildingOnForeignPlot != null;
    },
  },
};
